let Menu = {
  el: {
    ham: $('.menu'),
    menuTop: $('.menu-top'),
    menuMiddle: $('.menu-middle'),
    menuBottom: $('.menu-bottom'),
  },

  init: function() {
    Menu.bindUIactions()
  },

  bindUIactions: function() {
    Menu.el.ham.on('click', function(event) {
      Menu.activateMenu(event)
      event.preventDefault()
      $('.banner .nav-primary ul.nav').fadeToggle()
    })
  },

  activateMenu: function() {
    Menu.el.menuTop.toggleClass('menu-top-click')
    Menu.el.menuMiddle.toggleClass('menu-middle-click')
    Menu.el.menuBottom.toggleClass('menu-bottom-click')
  },
}

$('.dropdown-toggle-js').on('click', function() {
  $(this)
    .next()
    .fadeToggle()
})

export default {
  init() {
    // JavaScript to be fired on all pages

    Menu.init()

    $('p').each(function() {
      let $this = $(this)
      if ($this.html().replace(/\s|&nbsp;/g, '').length == 0) $this.remove()
    })
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}
